import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Input from "../boxes/Input";
import Dropdown from "../boxes/Dropdown";
import { logAnalyticsEvent } from "../../firebaseInit";
import { eventName } from "../../services/constants";

const ContactDetails = ({
  defaultValues,
  setContactDetails,
  states,
  statesOptions,
  lgas,
  onClose,
  pharmacies,
  MODALNAVIGATOR,
  rfcPharmacies,
  ...props
}) => {
  const [stage, setStage] = useState(1);

  const { prescriptions } = props;

  logAnalyticsEvent(
    eventName.VIEW_PRESCRIPTION_CONTACT_DETAILS,

    {
      item_name: prescriptions?.id,
    }
  );

  return (
    <div>
      <h2 className="text-[#323539] text-2xl font-bold my-6">Prescription</h2>

      <p className="text-xl text-[#383D43] font-bold mb-[2px]">
        Contact details
      </p>

      {stage === 1 ? (
        <StageOne
          pharmacies={pharmacies}
          rfcPharmacies={rfcPharmacies}
          setContactDetails={setContactDetails}
          defaultValues={defaultValues}
          states={states}
          statesOptions={statesOptions}
          lgas={lgas}
          onClose={onClose}
          MODALNAVIGATOR={MODALNAVIGATOR}
          setStage={setStage}
          props={props}
        />
      ) : (
        <StageTwo
          pharmacies={pharmacies}
          rfcPharmacies={rfcPharmacies}
          setContactDetails={setContactDetails}
          defaultValues={defaultValues}
          onClose={onClose}
          MODALNAVIGATOR={MODALNAVIGATOR}
          props={props}
        />
      )}
    </div>
  );
};

export default ContactDetails;

const StageOne = ({
  setContactDetails,
  defaultValues,
  states,
  statesOptions,
  lgas,
  setStage,
  onClose,
}) => {
  const [lgaOptions, setLgaOptions] = useState([]);

  const contactDetailsSchema = Yup.object().shape({
    state: Yup.string().required("State is required"),
    lga: Yup.object().required("LGA is required"),
    address: Yup.string().required("Street Address is required"),
    landmark: Yup.string().required("Landmark is required"),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    resetField,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(contactDetailsSchema),
    defaultValues,
  });

  // Watch state changes
  const selectedState = watch("state");

  useEffect(() => {
    // Update lgaOptions whenever selectedState changes
    if (selectedState) {
      const stateId = getIdByName(selectedState);
      const options = getStatesByStateId(stateId);

      setLgaOptions(
        options?.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
      resetField("lga");
    } else {
      setLgaOptions([]); // Reset lgaOptions if no state is selected
      resetField("lga");
    }
  }, [selectedState]);

  const onSubmit = (data) => {
    setContactDetails({ ...data });
    setStage(2);
  };

  const getIdByName = (name) => {
    const result = states.find((item) => item.name === name);
    return result ? result.id : null; // Returns the id or null if not found
  };

  const getStatesByStateId = (stateId) => {
    return lgas.filter((item) => item.state_id === stateId);
  };

  return (
    <form
      className="flex flex-col mt-8 gap-8"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="state"
        control={control}
        render={({ field }) => {
          return (
            <Dropdown
              label="What state are you currently resident?"
              name="states"
              placeholder="Select your state"
              id="states"
              {...field}
              options={statesOptions}
              error={errors.state?.message}
            />
          );
        }}
      />

      <Controller
        name="lga"
        control={control}
        render={({ field }) => {
          return (
            <Dropdown
              label="Local Government Area"
              name="lgas"
              placeholder="Select Local Government Area"
              id="lgas"
              {...field}
              options={lgaOptions}
              error={errors.lga?.message}
            />
          );
        }}
      />

      <Controller
        name="address"
        control={control}
        render={({ field }) => {
          return (
            <Input
              label="Street Address"
              name="street_address"
              id="street_address"
              placeholder="Enter Street Address"
              type="text"
              {...field}
              error={errors.address?.message}
            />
          );
        }}
      />

      <Controller
        name="landmark"
        control={control}
        render={({ field }) => {
          return (
            <Input
              label="Landmark / Nearest bus stop"
              name="landmark"
              id="landmark"
              placeholder="Enter your closest landmark"
              type="text"
              {...field}
              error={errors.landmark?.message}
            />
          );
        }}
      />

      <div className="flex items-center">
        <button
          className="w-1/2 bg-white border border-[#E5E5E7] py-3 rounded-[128px] text-[#323539] text-lg"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className={`w-1/2 ${
            isValid ? "bg-[#094063] text-white" : "bg-[#F8F9FB] text-[#E5E5E7]"
          } border border-[#E5E5E7] py-3 rounded-[128px] text-lg`}
          type="submit"
        >
          Next
        </button>
      </div>
    </form>
  );
};
const StageTwo = ({
  pharmacies,
  rfcPharmacies,
  setContactDetails,
  defaultValues,
  onClose,
  MODALNAVIGATOR,
  props,
}) => {
  const phoneNumberRegex = /^(0|\+2340?)[789]\d{9}$/;
  const contactDetailsSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(phoneNumberRegex, "Phone number is not valid"),

    alternativePhoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(phoneNumberRegex, "Alternative phone number is not valid"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(contactDetailsSchema),
    defaultValues,
  });
  const onSubmit = (data) => {
    const hasDelivery = () =>
      pharmacies.some(
        (pharm) =>
          pharm.state === data.state &&
          pharm.has_home_delivery.toLowerCase() === "yes"
      );

    const isRfcPresent = rfcPharmacies.some(
      (pharmacy) => pharmacy.state_object.name === data.state
    );
    setContactDetails({ ...data, isRfcPresent, hasDelivery: hasDelivery() });
    props.setModalContent(MODALNAVIGATOR.fullfillmentOptions);
  };
  return (
    <form
      className="flex flex-col mt-8 gap-8"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field }) => {
          return (
            <Input
              label="Phone Number"
              name="phone_number"
              id="phone_number"
              placeholder="Enter Phone Number"
              type="tel"
              {...field}
              error={errors.phoneNumber?.message}
            />
          );
        }}
      />

      <Controller
        name="alternativePhoneNumber"
        control={control}
        render={({ field }) => {
          return (
            <div>
              <Input
                label="Alternative Phone Number"
                name="alternative_phone_number"
                id="alternative_phone_number"
                placeholder="Enter Alternative Phone Number"
                type="tel"
                {...field}
                error={errors.alternativePhoneNumber?.message}
              />
              <p className="text-[#858C95] text-base mt-4 w-[480px] ">
                Add a second phone number so we can reach you if the first one
                is unavailable.
              </p>
            </div>
          );
        }}
      />

      <div className="flex items-center">
        <button
          className="w-1/2 bg-white border border-[#E5E5E7] py-3 rounded-[128px] text-[#323539] text-lg"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className={`w-1/2 ${
            isValid ? "bg-[#094063] text-white" : "bg-[#F8F9FB] text-[#E5E5E7]"
          }  border border-[#E5E5E7] py-3 rounded-[128px] text-lg`}
          type="submit"
        >
          Proceed
        </button>
      </div>
    </form>
  );
};
