import React, { useEffect } from "react";
import FulfilmentCard from "./cards/FulfilmentCard";
import { Heading } from "@reliance/design-system";
import DeliveryDislaimer from "./DeliveryDislaimer";
import { eventName } from "../services/constants";

import { logAnalyticsEvent } from "../firebaseInit";
const FulfilmentOptions = ({
  onProceedRecommendedPickup,
  onProceedDelivery,
  onProceedPickup,
  handleRFCLocations,
  showRfc,
  hasDelivery,
  contactDetails,
  ...props
}) => {
  const { prescriptionId } = props;

  logAnalyticsEvent(eventName.VIEW_PRESCRIPTION_FULFILLMENT_OPTIONS, {
    states: contactDetails?.state,
    item_name: prescriptionId,
  });
  return (
    <div>
      <Heading level={"h4"}>Receive your prescription</Heading>
      <div style={{ fontSize: 16, marginBottom: 12, marginTop: 10 }}>
        How do you want to get your prescription?
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        {showRfc && (
          <FulfilmentCard
            options={[
              handleRFCLocations() && `Located in ${handleRFCLocations()}`,
              "Medication availability",
              "Pickup within 2 hours",
              "Ministry of Health-tested medication",
              "Pharmacist expertise",
            ].filter(Boolean)} // Filters out any empty strings
            onClick={onProceedRecommendedPickup}
            recommended={true}
            deliveryType="pickup"
          />
        )}
        <FulfilmentCard
          options={["Multiple Locations", "Pickup within 3 hours"]}
          onClick={onProceedPickup}
          recommended={false}
          deliveryType={"pickup"}
        />

        {hasDelivery && (
          <FulfilmentCard
            options={["Medication delivered within 24hrs"]}
            recommended={false}
            deliveryType={"delivery"}
            onClick={onProceedDelivery}
          >
            <DeliveryDislaimer />
          </FulfilmentCard>
        )}
      </div>
    </div>
  );
};

export default FulfilmentOptions;
