import React from "react";
import PharmacyItem from "./PharmacyItem";
import { Button, Heading, Text } from "@reliance/design-system";
import House from "../img/House.png";
import { logAnalyticsEvent } from "../firebaseInit";
import { eventName } from "../services/constants";

const ConfirmPharmacySelectionV2 = ({
  pharmacy,
  handleGoback,
  handleProceed,
  contactDetails,
  pickupType,
  ...props
}) => {
  const { prescriptionId } = props;

  logAnalyticsEvent(eventName.VIEW_PRESCRIPTION_PICKUP_CONFIRMATION, {
    states: contactDetails?.state,
    item_name: prescriptionId,
    type: "pickup",
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 15,
        }}
      >
        <img src={House} alt=" " width={40} />
      </div>
      <div>
        <Heading
          level={"h3"}
          style={{ textAlign: "center", marginBottom: 10, fontSize: 18 }}
        >
          Confirm selection
        </Heading>
      </div>
      <PharmacyItem pharmacy={pharmacy} />
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <Text as="div" variant="lg/normal" style={{ fontSize: 16 }}>
          {" "}
          Are you sure you want to proceed with this Pharmacy?
        </Text>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Button
            variant={"outline"}
            onClick={() => {
              logAnalyticsEvent(eventName.REJECT_PRESCRIPTION_PICKUP_SLA, {
                states: contactDetails?.state,
                item_name: prescriptionId,
                type: pickupType,
              });
              handleGoback();
            }}
            style={{ width: "48%", fontSize: 15, height: 40 }}
          >
            Go back
          </Button>
          <Button
            onClick={() => {
              logAnalyticsEvent(eventName.ACCEPT_PRESCRIPTION_PICKUP_SLA, {
                states: contactDetails?.state,
                item_name: prescriptionId,
                type: pickupType,
              });
              handleProceed();
            }}
            style={{ width: "48%", fontSize: 15, height: 40 }}
          >
            Proceed
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPharmacySelectionV2;
