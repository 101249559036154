import React from "react";
import bag from "../../src/img/bag.svg";

export default ({ onClick, deliveryMethod }) => {
  const onClickBack = () => {
    onClick();
    window.location.reload();
  };

  return (
    <div
      style={{
        display: "flex",
        padding: "12px 0px",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
        flex: "1 0 0",
        borderRadius: "2.62px",
        fontFamily: "Avenir",
      }}
    >
      <div style={{ width: "190px", height: "153.81px" }}>
        <img src={bag} alt="" />
      </div>
      <div
        style={{
          display: "flex",
          width: "280px",
          flexDirection: "column",
          color: "var(--black, #10111E)",
          fontSize: "19.653px",

          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "23.584px",
          letterSpacing: "-0.655px",
          textTransform: "capitalize",
        }}
      >
        {deliveryMethod === "delivery"
          ? "Drug delivery request was submitted successfully"
          : "Drug Pick-up request was submitted successfully"}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "stretch",
          color: "var(--graphite, #495660)",
          fontSize: "13.102px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "18.343px",
          letterSpacing: "-0.066px",
        }}
      >
        {deliveryMethod === "pickUp" && (
          <p>
            We will confirm the availability of the drugs at the pharmacy and
            notify you via SMS/Notification soon
          </p>
        )}
        {deliveryMethod === "delivery" && (
          <p>
            We will confirm the availability of the drugs for delivery and
            notify you via SMS/Notification in 10mins or less
          </p>
        )}
      </div>

      <button
        style={{
          display: "flex",
          width: "292px",
          padding: "4px 16px",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          borderRadius: "4px",
          background: "var(--primary-color-baby-blue, #107BC0)",
          color: "var(--white, #FFF)",
          fontSize: "16px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "24px",
          letterSpacing: "-0.2px",
        }}
        onClick={onClickBack}
      >
        Back to consultation
      </button>
    </div>
  );
};
