import React, { useState } from "react";

const Dropdown = ({
  label,
  id,
  name,
  placeholder,
  value,
  onChange,
  options,
  required = false,
  disabled = false,
  className = "",
  error,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasError = !!error;

  const handleOptionClick = (option) => {
    onChange({ target: { name, value: option } });
    setIsOpen(false);
  };

  return (
    <div className={`relative ${className}`}>
      {label && (
        <label
          htmlFor={id}
          className="block text-lg text-[#323539] font-bold mb-2"
        >
          {label}
        </label>
      )}
      <div
        className={`shadow appearance-none border-[#E5E5E7] border rounded-[128px] w-full py-[14px] px-4 text-lg  focus:outline-none focus:shadow-outline text-[#323539] ${
          hasError ? "border-[#EF4444]" : ""
        } ${disabled ? "opacity-50" : "cursor-pointer"}`}
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        <div className="flex justify-between items-center">
          <span>{value?.label ? value.label : value || placeholder}</span>
          <svg
            className={`w-6 h-6 text-gray-400 transform ${
              isOpen ? "rotate-180" : ""
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </div>
      </div>

      {isOpen && (
        <ul
          className="absolute z-10 w-full bg-white border border-[#E2E8F0] rounded-[16px] mt-2 shadow-lg max-h-60 py-4 overflow-auto"
          {...props}
        >
          {options.map((option, index) =>
            option.label ? (
              <li
                key={index}
                className={`px-4 py-2 cursor-pointer hover:bg-gray-200 ${
                  option.value === value ? "bg-gray-100" : ""
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {option.label ? option.label : option}
              </li>
            ) : (
              <li
                key={index}
                className={`px-4 py-2 cursor-pointer hover:bg-gray-200 ${
                  option === value ? "bg-gray-100" : ""
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </li>
            )
          )}
        </ul>
      )}

      {hasError && <p className="text-lg text-[#EF4444] mt-2">{error}</p>}
    </div>
  );
};

export default Dropdown;
